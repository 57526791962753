<template>
    <div class="zt-page-content">
        <div class="zt-block" style="height:calc(100% - 40px)">
            <!-- <div class="thead flex">
                <div style="margin-right:30px;">
                    <el-select v-model="orderState" @change="order_state" :popper-append-to-body="false" placeholder="全部">
                        <el-option v-for="item in options" :key="item.id"
                        :label="item.value" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-block flex">
                    <el-input v-model="searchValue" placeholder="输入商品名称" 
                        prefix-icon="el-icon-search"></el-input>
                    <el-button @click="search">搜索</el-button>
                </div>
            </div> -->
            <div style="height:calc(100% - 42px);overflow-y:auto;" ref="load" class="table flex-1">
                <div v-if="list && list.length>0" class="tbody">
                    <div v-for="(item,idx) in list" :key="idx" class="order-block">
                        <div class="order-body flex">
                            <div class="flex-1 flex">
                                <div @click="gotoDetail()" style="width:80px;height:80px;margin-right:12px;">
                                    <div v-if="item.photo_render && item.photo_render.length>0" class="bg_img"
                                        :style="{backgroundImage: 'url(' + imgUrl+item.photo_render[0]+'!width_250px' + ')'}"></div>
                                </div>
                                <div>
                                    <div class="font14-grey ellipsis2" style="width:350px;margin-bottom:5px;"><b>{{item.name}}</b></div>
                                    <div class="font14-grey1">型号：{{item.module_no}}</div>
                                </div>
                            </div>
                            <!-- <div class="btn-container flex">
                                <div class="btn-c-left flex flex-align-center">
                                    <div class="bcl-til">渠道分组</div>
                                    <div class="state flex flex-align-center">
                                        <span v-if="item.stat!=3" class="state-item blue">
                                            <i class="el-icon-check"></i>已上架
                                        </span>
                                        <span v-if="item.market_publish==0  || item.stat==3" class="state-item">
                                            <i class="el-icon-close"></i>未上架
                                        </span>
                                    </div>
                                    <el-button v-if="item.stat!=3"
                                        type="text" size="small" @click="changeSwitch(item)">下架</el-button>
                                    <el-button v-if="item.stat!=3"
                                        type="text" size="small" @click="changeSwitch(item)">上架</el-button>
                                </div>
                                <div class="btn-c-right flex flex-align-center">
                                    <div class="bcl-til">渠道删除</div>
                                    <div class="state flex flex-align-center">
                                        <span v-if="item.stat!=3" class="state-item">
                                            <i class="el-icon-delete-solid" style="border:none;"></i>
                                        </span>
                                        <span v-if="item.market_publish==0  || item.stat==3" class="state-item">
                                            <i class="el-icon-close"></i>未上架
                                        </span>
                                    </div>
                                    <el-button v-if="item.stat!=3"
                                        type="text" size="small" @click="changeSwitch(item)">下架</el-button>
                                    <el-button v-if="item.stat!=3"
                                        type="text" size="small" @click="changeSwitch(item)">上架</el-button>
                                </div>
                            </div> -->
                        </div>
                        <div class="order-foot flex">
                            <div v-show="listIndex==idx" v-if="item.step_price && item.step_price.length>0" class="skuList">
                                <div class="skuhead flex">
                                    <div class="flex-1" style="max-width:160px;">材质</div>
                                    <div class="flex-1" style="max-width:160px;">颜色</div>
                                    <div class="flex-1" style="max-width:160px;" v-for="(attr,idx2) in item.step_price[0].attrs" :key="idx2">{{attr.name}}</div>
                                    <!-- <div v-if="item.step_price[0].attrs && item.step_price[0].attrs.length>0" class="flex"></div> -->
                                    <!-- <div class="input flex-1">我的工厂参考价</div>
                                    <div class="input flex-1">二级参考批发价</div>
                                    <div class="input flex-1">三级参考批发价</div>
                                    <div class="input flex-1">建议零售参考价</div> -->
                                </div>
                                <div class="skubody">
                                    <div class="skubody-item flex" v-for="(sku,idx1) in item.step_price" :key="idx1">
                                        <div class="flex-1" style="max-width:160px;">{{sku.material_name}}</div>
                                        <div class="flex-1" style="max-width:160px;">{{sku.color_name}}</div>
                                        <div class="flex-1" style="max-width:160px;" v-for="(attr,idx2) in sku.attrs" :key="idx2">{{attr.value}}</div>
                                        <!-- <div v-if="sku.attrs && sku.attrs.length>0" class="flex"></div> -->
                                        <!-- <div class="input flex-1 flex">
                                            <div>￥</div>
                                            <div class="flex-1"><el-input v-model="sku.priceLv1" size="small"></el-input></div>
                                        </div>
                                        <div class="input flex-1 flex">
                                            <div>￥</div>
                                            <div class="flex-1"><el-input v-model="sku.priceLv2" size="small"></el-input></div>
                                        </div>
                                        <div class="input flex-1 flex">
                                            <div>￥</div>
                                            <div class="flex-1"><el-input v-model="sku.priceLv3" size="small"></el-input></div>
                                        </div>
                                        <div class="input flex-1 flex">
                                            <div>￥</div>
                                            <div class="flex-1"><el-input v-model="sku.priceLv4" size="small"></el-input></div>
                                        </div> -->
                                    </div>
                                </div>
                                <!-- <div class="sku-button flex flex-justify-around">
                                    <el-button @click="packdown()" style="width:76px;" size="small" type="primary" plain>取消</el-button>
                                    <el-button @click="edit(item)" style="width:76px;" size="small" type="primary">保存</el-button>
                                </div> -->
                            </div>
                            <div class="skubutton flex flex-center">
                                <a @click="packdown()" v-if="listIndex==idx">收起</a>
                                <a @click="packup(item,idx)" v-else>展开</a>
                                <i @click="packdown()" v-if="listIndex==idx" class="active iconfont icon-youjiantou"></i>
                                <i @click="packup(item,idx)" v-else class="iconfont icon-youjiantou"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="flex flex-v flex-center font14-grey1" style="height:100%">暂无数据</div>
            </div>
            <div style="padding:15px 0;text-align:center;">
                <el-pagination background
                layout="prev, pager, next"
                :current-page.sync="queryOpt.page"
                :page-size="queryOpt.pagesize"
                :total="total"
                ></el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
    import { IMG_URL_PRE } from "@/config";
    import { debounce } from 'lodash';
    import { listAgencyGroupProduct } from '@/service/white';
    import { setFxProductPrice, statFxProduct, getStoreWxqr, delFxProduct } from '@/service/sharer';
    export default {
        components: {},
        data() {
            return {
                imgUrl:IMG_URL_PRE,
                // 商品状态
                options: [
                    { id: 0, value: "全部" },
                    { id: 1, value: "待付款" },
                    { id: 2, value: "待我方确认" },
                    { id: 3, value: "交易完成" }
                ],
                orderState:0,
                searchValue:"",
                queryOpt:{
                    group_id:null,
                    page:1,
                    pagesize:10,
                },
                total: 15,
                list:[],
                listIndex:null,
            }
        },
        watch: {
            queryOpt: {
                handler() {
                    this.debounceRefreshItems();
                },
                deep: true
            },
        },
        created() {
            this.debounceRefreshItems = debounce(this.refreshItems, 500);
            this.queryOpt.group_id = this.$route.params.id;
        },
        mounted() {
            this.refreshItems();
        },
        methods: {
            gotoDetail(item) {
                console.log(item);
                switch (item.price_type) {
                    case 2:
                    window.open('/sale/mall/home/factory_detail/'+item.factory_product_no); break;
                    case 1:
                    window.open('/sale/mall/home/design_detail/'+item.factory_product_no); break;
                }
            },
            refreshItems() {
                console.log('refreshItems',this.$refs['load']);
                this.list = [];
                const _loading = this.$loading({
                    lock: true,
                    text: "拼命加载中....",
                    target:this.$refs.load
                });
                listAgencyGroupProduct(this.queryOpt).then(rst => {
                    this.total=rst.total;
                    this.list = rst.list;
                    console.log(this.list);
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            packup(item,idx) {
                // console.log(idx);
                this.listIndex = idx;
            },
            packdown(item) {
                console.log(item);
                // this.refreshItems();
                this.listIndex = null;
            },
            changeSwitch(item,index) {// 上下架
                let text=null,stat=null;
                if (item.fx_stat==1) {
                    text = "下架"; stat = 2;
                } else if (item.fx_stat==2) {
                    text = "上架"; stat = 1;
                }
                this.$confirm('确认是否'+text+'?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    statFxProduct({fx_product_id:item.fx_product_id,stat:stat}).then(()=>{
                        // this.loadItems();
                        this.list[index].fx_stat = stat;
                        this.$message.success(text+'成功！');
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                });
                console.log(item);
            },
            edit(item) {
                if (item && item.sku && item.sku.length>0) {
                    let obj = {
                        fx_product_id:item.fx_product_id,
                        price_list:[]
                    }
                    item.sku.forEach(e => {
                        obj.price_list.push({
                            sku_no: e.factory_sku_no,
                            price_lv1: parseFloat(e.priceLv1),
                            price_lv2: parseFloat(e.priceLv2),
                            price_lv3: parseFloat(e.priceLv3),
                            price_lv4: parseFloat(e.priceLv4),
                        })
                    });
                    setFxProductPrice(obj).then(()=>{
                        this.refreshItems();
                        this.packdown();
                        this.$message.success('保存成功!');
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                }
            },
            // 删除
            del(item) {
                this.$confirm('确认是否从商品库移除?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    delFxProduct({fx_product_id:item.fx_product_id}).then(()=>{
                        this.refreshItems();
                        this.$message.success('移除成功!');
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                });
            },
        }
    }
</script>
<style src="../../style.css"></style>
<style scoped lang="less">
.zt-block {
    background: transparent;
    box-shadow: none;
}
.thead {
  padding: 0 0 20px;
    .input-block {
        .el-input {
            max-width:200px;
        }
        .el-button {
            width: 76px;
            left: -4px;
            position: relative;
            z-index: 2;
        }
    }
}
.table {
  .tbody {
    .order-block {
        // border-left: 1px solid #e6e6e6;
        // border-right: 1px solid #e6e6e6;
        // border-bottom: 1px solid #e6e6e6;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
        margin-bottom: 16px;
      .order-body {
            padding: 20px 0;
            margin: 0 40px;
            border-bottom: 1px solid #CCCCCC;
            .state {
                height: 36px;
                border-top: 1px solid #E6E6E6;
                >div {
                    // border-left: 1px solid #707070;
                    padding: 0 24px;
                    &:first-child {
                        padding-left: 0;
                        border-left: 0;
                    }
                    .el-button {
                        font-size:14px;
                        color:#5074EE;
                    }
                }
                .state-item {
                    font-size: 12px;
                    color: #808080;
                    i {
                        font-size: 12px;
                        color: #808080;
                        border: 1px solid #808080;
                        margin-right: 5px;
                    }
                    &.blue {
                        // color: #5074EE;
                        i {
                            border: 1px solid #5074EE;
                            color: #5074EE;
                        }
                    }
                    &.green {
                        // color: #5074EE;
                        i {
                            border: 1px solid #5074EE;
                            color: #5074EE;
                        }
                    }
                }
            }
            .btn-container {
                padding: 10px 0 5px 0;
                .btn-c-left {
                    margin-right: 40px;
                    cursor: pointer;
                }
                .btn-c-right {
                    cursor: pointer;
                }
                >div {
                    height: 40px;
                    padding: 0 10px;
                    background: #F5F5F5;
                    .bcl-til {
                        font-size: 14px;
                        font-weight: 600;
                        color: #4D4D4D;
                    }
                    .state {
                        border: none;
                        margin:0 10px;
                    }
                    .el-button {
                        font-size:14px;
                        color:#5074EE;
                    }
                }
            }
      }
      .order-foot {
        width: 100%;
        position: relative;
        text-align: center;
        .skuList {
            width: 100%;
            background: #F5F5F5;
            padding: 0 40px;
            transition: all 0.2s linear;
        }
        .skuhead {
            height: 60px;
            line-height: 60px;
            font-size: 16px;
            font-weight: 600;
            color: #4D4D4D;
            border-bottom: 1px solid #CCCCCC;
            .input {
                width: 135px;
                padding: 0 4px;
            }
        }
        .skubody {
            border-bottom: 1px solid #CCCCCC;
            .skubody-item {
                height: 50px;
                line-height: 50px;
                font-size: 14px;
                color: #4D4D4D;
                .input {
                    width: 135px;
                    padding: 0 4px;
                }
                .input .el-input {
                    max-width: 120px;
                    // padding-left: 2px;
                }
            }
        }
        .skubutton {
            width: 100%;
            cursor: pointer;
            a {
                font-size: 14px;
                color: #0091FF;
                padding: 10px 0;
            }
            i {
                display: inline-block;
                transform:rotate(90deg);
                -ms-transform:rotate(90deg); 	/* IE 9 */
                -moz-transform:rotate(90deg); 	/* Firefox */
                -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
                -o-transform:rotate(90deg); 	/* Opera */
                font-size: 12px;
                color: #0091FF;
                padding: 5px;
                &.active {
                    display: inline-block;
                    transform:rotate(270deg);
                    -ms-transform:rotate(270deg); 	/* IE 9 */
                    -moz-transform:rotate(270deg); 	/* Firefox */
                    -webkit-transform:rotate(270deg); /* Safari 和 Chrome */
                    -o-transform:rotate(270deg); 	/* Opera */
                }
            }
        }
      }
    }
    .order-block:last-child {
        margin-bottom: 0;
    }
  }
}
</style>